import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { territorymanagementlist } from '../../actions';
import { delete_territory } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate, isPermission } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';


const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const Entries = lazy(() => import('../../components/entries/entires'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));
const SearchInput = lazy(() => import('../../components/searchInput'));


let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
class Territorymanagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            code: '',
            isLoading: false,
            deleteMessage: "Territory has been Deleted Successfully",
            serverErrors: ["territory_delete", "territorymanagement_res"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: true,
                    isShow: true,
                },
                {
                    name: 'code',
                    label: "Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'zone.name',
                    label: "Zone",
                    type: "text",
                    sortable: true,
                    isShow: true,
                },
                {
                    name: 'region.name',
                    label: "Region",
                    type: "text",
                    sortable: true,
                    isShow: true,
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect

                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ]
        }
    }


    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }



    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }


    loadResult = () => {
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined, code: this.state.code ? this.state.code : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.territorymanagementlist(data);
        this.setState({ isLoading: true })
    }

    resetfilter = () => {
        let data = {}
        data.filter = { name: '', code: '' }
        this.props.territorymanagementlist(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };



    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_territory(item._id);
            e.preventDefault();
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "territory_delete", this.loadResult, this.state.deleteMessage);
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "territorymanagement_res") });
            }
        }

        return true;
    }


    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }
    callforDebounce = () => this.loadResult();



    render() {
        const { territorymanagement_res } = this.props;
        const { name } = this.state;
        const totResult = territorymanagement_res !== undefined ? territorymanagement_res.count : 0;
        return (

            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Territory Management"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === 0 ? null : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    {isPermission("territory","create") ? 
                                                        <LinkWithItag to={routehelp.Territorymanagementadd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Territory" />
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="table-responsive">
                                                    {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"territory"}/> :
                                                        this.state.isLoading ? <Loader /> : territorymanagement_res !== undefined && territorymanagement_res.count === 0 ? "No Data Found" : territorymanagement_res && territorymanagement_res.count > 0 ?
                                                            <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={territorymanagement_res} props={this.props} moduleName={"territory"} />
                                                            : "Something Went Wrong"}
                                                </div>
                                            </div>
                                        </div>
                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            /* <aside className="right-sidebar p-0 scrollbarcustom ">
                 <div className="modal-header bg-primary rounded-0">
                     <h6 className="modal-title"> Filter</h6>
                     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                     </button>
                 </div>
                 <div className="card-body">
                     <form>
                         <div className="form-group row">
                             <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                             <div className="col-sm-9">
                                 <input type="text" className="form-control" id="name" name="name" onChange={this.onChange} value={this.state.name} placeholder="Name" />
                             </div>
                         </div>
                         <div className="form-group row">
                             <label htmlFor="Mobile" className="col-sm-3 col-form-label">Code</label>
                             <div className="col-sm-9">
                                 <input type="text" className="form-control" name="code" value={this.state.code} onChange={this.onChange} id="code" placeholder="Code" />
                             </div>
                         </div>
                         <div className="form-group row">
                             <label htmlFor="Mobile" className="col-sm-3 col-form-label">Create at</label>
                             <div className="col-sm-9">
                                 <div className="date filterdate float-left" id="datepicker-bottom-left">
                                     <input type="date" className="form-control m-input" placeholder="Start Date" />
                                     <span className="input-group-addon">
                                         <i className="fa fa-calendar"></i>
                                     </span>
                                 </div>
                                 <div className="todate">TO</div>
                                 <div className="date filterdate float-left" id="datepicker-bottom-left">
                                     <input type="date" className="form-control m-input" placeholder="End Date" />
                                     <span className="input-group-addon">
                                         <i className="fa fa-calendar"></i>
                                     </span>
                                 </div>
                             </div>
                         </div>
                     </form>
                 </div>
                 <div className="col-md-6 mb-4 offset-md-3 text-center">
                     <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                     <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark navbar-toggler right-sidebar-toggler pointer">Reset</button>
                 </div>
             </aside> */

        )
    }
}

function mapStateToProps(state) {
    return {
        territorymanagement_res: state.territorymanagement_res,
        territory_delete: state.territory_delete
    };
}


const mapDispatchToProps = dispatch => ({
    territorymanagementlist: data => dispatch(territorymanagementlist(data)),
    delete_territory: item_id => dispatch(delete_territory(item_id))
});

Territorymanagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(Territorymanagement);

export default Territorymanagement;