import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { delete_agency, agencyUpload, agencyList } from '../../actions';
import { routehelp } from '../../Routehelper';
import toastr from 'reactjs-toastr';
import { commonDebounce, errorRender, isPermission, loaderUpdate, redirect, successRender } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import { CSVLink } from "react-csv";
import 'reactjs-toastr/lib/toast.css';

const SearchInput = lazy(() => import('../../components/searchInput'));
const SingleBrandDropDown = lazy(() => import('../../container/brandsManagement/singleBrandDropDown'));
const CustomerDropDown = lazy(() => import('../../container/customerManagement/customerDropdown'));
const Loader = lazy(() => import('../../components/loader'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Entries = lazy(() => import('../../components/entries/entires'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();

const csvData = [
    ["brand", "customer", "agent", "agencyName", "code"],
    ["DON & JULIO", "RESHMA GARMENT", "DINESH", "Agency1", "A1"],
    ["DON & JULIO", "RESHMA GARMENT", "CROMPTON TESTER", "Agency1", "A1"],
    ["VERCELLI", "RESHMA GARMENT", "OLIVER TESTER", "Agency1", "A1"],
    ["DON & JULIO", "RESHMA GARMENT", "ANKIT  D", "Agency2", "A2"],
    ["DON & JULIO", "RESHMA GARMENT", "OLIVER TESTER", "Agency2", "A2"]
];
class Agency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            code: '',
            isLoading: false,
            brand: null,
            customer: null,
            isFilter: false,
            isUploadPop: false,
            successRequest: [{ name: "delete_agencyRes", msg: 'Agency has been Deleted Successfully', function: this.loadResult },
            { name: "agencyUploadresponse", msg: 'File has been uploaded Succesfully', function: this.clearValues }],
            serverError: ["delete_agencyRes", "agencyUploadresponse", "agencyListRes"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Agency Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'code',
                    label: "Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ]
        };
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
    }

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        file = new FormData();
        let data = {};
        data.filter = { name: this.state.name !== "" ? this.state.name : undefined, code: this.state.code !== "" ? this.state.code : undefined, 
        brand: this.state.brand !== null ? this.state.brand.value : undefined, 
        customer: this.state.customer !== null ? this.state.customer.value : undefined };
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip =  (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.agencyList(data);
        this.setState({ isLoading: true, isFilter: false });
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    resetfilter = () => {
        let data = {};
        data.filter = { name: undefined, code: undefined, brand: undefined, customer: undefined };
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.agencyList(data);
        this.setState({ isFilter: false, isLoading: true, name: '', code: '', brand: null, customer: null });
    }

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

    handleFile = (event) => {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].name.includes(".csv")) {
                file.append('file', event.target.files[0]);
            } else {
                document.getElementById("myFile").value = "";
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    changeDropdownValue = (value, name) => this.setState({ [name]: value });

    fileupload = () => {
        if (document.getElementById("myFile").value === "") {
            return toastr.error('Please Select Csv file ', 'error', {
                displayDuration: 5000, width: '400px'
            });
        }
        this.props.agencyUpload(file);
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_agency(item._id);
            e.preventDefault();
        }
    }

    clearFile = () => {
        this.setState({ brand: null })
        document.getElementById("myFile").value = "";
        file = new FormData();
    }

    clearValues = () => {
        this.setState({ brand: null });
        document.getElementById("myFile").value = "";
        this.loadResult();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "agencyListRes") });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el, el === "agencyUploadresponse" ? this.clearFile : null));
            this.state.successRequest.forEach((el) => successRender(nextProps, el.name, el.function, el.msg));
        }
        return true;
    }

    render() {
        const { agencyListRes } = this.props;
        const { name } = this.state;
        const totResult = agencyListRes !== undefined ? agencyListRes.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Agency"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    {isPermission("agency", "create") ? 
                                                        <LinkWithItag to={routehelp.agencyAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Agency" />
                                                    : null}
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary mr-2" datatoggle="modal" datatarget=".bd-example-modal-lg1" dataplacement="top" title="Import a file" data-original-title="Click to Upload all" />
                                                    <ButtonWithItag classNameI="ti-filter" onclick={this.toggleFilter} type="button" className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"agencyUploadSample.csv"}
                                                        className="btn btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade bd-example-modal-lg1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">Agency Upload</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="custom-file mt-2 col">
                                                            <input accept=".csv" onChange={this.handleFile} type="file" id="myFile" name="filename2"></input>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" onClick={this.fileupload} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                        <button type="button" className="btn btn-secondary" onClick={this.clearFile} data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"agency"} /> :
                                            this.state.isLoading ? <Loader /> : agencyListRes !== undefined && agencyListRes.count === 0 ? "No Data Found" : agencyListRes && agencyListRes.count > 0 ? <div className="row">
                                                <div className="col-sm-12 display-grid">
                                                    <div className="table-responsive">
                                                        <DataTable mappingObj={this.state.isOnlineMapping} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={agencyListRes} props={this.props} moduleName={"agency"} />
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}
                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button onClick={this.toggleFilter} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            {/* <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label">Agency Name</label>
                                <div className="col-sm-9">
                                    <input maxlength="20" type="text" className="form-control" id="name" name="name" onChange={this.onChange} value={this.state.name} placeholder="Agency Name" />
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label htmlFor="Mobile" className="col-sm-3 col-form-label">Code</label>
                                <div className="col-sm-9">
                                    <input maxLength="10" type="text" className="form-control" name="code" value={this.state.code} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                            </div>
                            <div className="form-group">
                                <SingleBrandDropDown
                                    ondropdownChange={(e) => this.changeDropdownValue(e, "brand")}
                                    placeholder="Search Brand"
                                    value={this.state.brand}
                                />
                            </div>
                            <div>
                                <CustomerDropDown
                                    ondropdownChange={(e) => this.changeDropdownValue(e, "customer")}
                                    placeholder="Search Customer"
                                    value={this.state.customer}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark ">Reset</button>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        agencyListRes: state.agencyListRes,
        delete_agencyRes: state.delete_agencyRes,
        agencyUploadresponse: state.agencyUploadresponse,
    };
}


const mapDispatchToProps = dispatch => ({
    agencyList: data => dispatch(agencyList(data)),
    delete_agency: id => dispatch(delete_agency(id)),
    agencyUpload: file => dispatch(agencyUpload(file))
});

Agency = connect(
    mapStateToProps,
    mapDispatchToProps
)(Agency);

export default Agency;