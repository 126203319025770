import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { productmanagementlist } from '../../actions';
import { delete_product, productUpload, productexport, unitCodeByShade } from '../../actions';
import { routehelp } from '../../Routehelper';
import { Link } from 'react-router-dom';
import toastr from 'reactjs-toastr';
import { CSVLink } from "react-csv";
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate, validate, isPermission } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import 'reactjs-toastr/lib/toast.css';
import ExportModal from '../modal/list';
// import Translate from '../../libs/translation';

const Branddropdown = lazy(() => import('../brandsManagement/brandDropdown'));
const Entries = lazy(() => import('../../components/entries/entires'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));
const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();
let unitFile = new FormData();


const csvData = [
    ["product", "exmillprice", "convFact", "whlprice", "shade", "unitname", "unitcode", "defaultUOM", "shadecode", "shadedescription"],
    ["TEST-B2", "440", "13", "490", "1", "pcs", "8887676566", "taka", "shade code 1", "shade desc 1"],
    ["TEST-B2", "440", "13", "490", "1", "taka", "8887676567", "taka", "", ""],
    ["TEST-B2", "440", "13", "490", "1", "than", "8887676568", "taka", "", ""],
    ["TEST-B2", "440", "13", "490", "1", "stlt", "8887676569", "taka", "", ""],
    ["TEST-B2", "440", "13", "490", "1", "smalltaka", "8887676560", "taka", "", ""],
    ["TEST-B2", "440", "13", "490", "1", "rolls", "8887676561", "taka", "", ""],
    ["TEST-B2", "440", "13", "490", "1", "meter", "8887676562", "taka", "", ""],
];

class Productmanagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            code: '',
            isLoading: false,
            brand: '',
            filterBrand: '',
            isFilter: false,
            isExportLoader: false,
            isUploadPop: false,
            deleteMessage: "Product has been Deleted Successfully",
            serverError: ["product_delete", "productexportresponse"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'code',
                    label: "Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                // {
                //     name: 'itemCode',
                //     label: "Item Code",
                //     type: "text",
                //     sortable: true,
                //     isShow: true
                // },
                {
                    name: 'brand.name',
                    label: "Brand",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'price',
                    label: "Ex-mill price",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                // {
                //     name: 'convFact',
                //     label: "ConvFact",
                //     type: "text",
                //     sortable: true,
                //     isShow: true
                // },
                {
                    name: 'createdAt',
                    label: "Created At",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'updatedAt',
                    label: "Updated At",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ],
            isBackButton: false
        };
    }


    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
        var params = this.queryStringParse(this.props.location.search);
        if (params && params.dashboard === 'true') {
            // console.log(params);
            this.setState({
                isBackButton: true
            });

        }
    }

    queryStringParse = function (string) {
        let parsed = {};
        if (string) {
            string = string.substring(string.indexOf('?') + 1)
            let p1 = string.split('&')
            p1.forEach((value) => {
                let params = value.split('=')
                parsed[params[0]] = params[1]
            });
        }
        return parsed
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }


    loadResult = () => {

        if (this.state.isFilter === true) {
            currentPage = 1;
        };

        file = new FormData();
        unitFile = new FormData();

        let data = {};
        data.filter = { name: this.state.name !== "" ? this.state.name : undefined, code: this.state.code !== "" ? this.state.code : undefined, brand: this.state.filterBrand && this.state.filterBrand.value ? this.state.filterBrand.value : undefined };
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;


        this.props.productmanagementlist(data);
        this.setState({ isLoading: true, isFilter: false });
    };

    ondropdownChange = (e, t) => this.setState({ [e]: t });


    export = () => {
        this.setState({
            isExportLoader: true,
        });
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    };

    shadeUpload = () => {
        document.getElementById("myFileUnitCode").value = "";
        this.setState({ isUploadPop: !this.state.isUploadPop });
    };

    resetfilter = () => {
        let data = {};
        data.filter = { name: undefined, code: undefined, brand: undefined };
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.productmanagementlist(data);
        this.setState({ isFilter: false, isLoading: true, name: '', code: '', filterBrand: null });
    };

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

    handleFile = (event) => {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].name.includes(".csv")) {
                file.append('file', event.target.files[0]);
                file.append('brand', this.state.brand && this.state.brand.value);
            } else {
                document.getElementById("myFile").value = "";
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    };

    handleUnitFile = (event) => {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].name.includes(".csv")) {
                unitFile.append('file', event.target.files[0]);
            } else {
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    };

    fileupload = () => {
        if (this.state.brand === "") {
            return toastr.error('Please Select Brand ', 'error', {
                displayDuration: 5000, width: '400px'
            });
        } else if (document.getElementById("myFile").value === "") {
            return toastr.error('Please Select File ', 'error', {
                displayDuration: 5000, width: '400px'
            });
        }
        else {
            this.props.productUpload(file);
        }
    };

    unitFileUpload = () => this.props.unitCodeByShade(unitFile);

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_product(item._id);
            e.preventDefault();
        }
    }

    clearFile = () => {
        this.setState({ brand: "" })
        document.getElementById("myFile").value = "";
        file = new FormData();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "productmanagement_res") });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "product_delete", this.loadResult, this.state.deleteMessage);

            if (nextProps.productexportresponse !== undefined && nextProps.productexportresponse.success === true) {
                nextProps.productexportresponse.success = null;
                toastr.success("The product list will be sent to you within 1 hour via email.", "Success", {
                    displayDuration: 5000,
                });
                this.setState({
                    email: '',
                    isExportLoader: false
                });
            }

            if (nextProps.productUploadresponse !== undefined && nextProps.productUploadresponse.success === false && this.props !== nextProps) {
                nextProps.productUploadresponse.errors.forEach((item, index) => {
                    toastr.error(item.msg, 'Error', {
                        displayDuration: 5000
                    })
                }
                );
                this.setState({ brand: "" });
                document.getElementById("myFile").value = "";
                nextProps.productUploadresponse.success = null;
            }
            if (nextProps.unitCodeByShade_recieve !== undefined && nextProps.unitCodeByShade_recieve.success === true) {
                toastr.success("File has been uploaded Succesfully");
                document.getElementById("myFile").value = "";
                this.loadResult();
                nextProps.unitCodeByShade_recieve.success = null;
            }
            if (nextProps.productUploadresponse !== undefined && nextProps.productUploadresponse.success === true && this.props !== nextProps) {
                toastr.success("File has been uploaded Succesfully");
                // this.state.brand = "";
                this.setState({ brand: "" });
                document.getElementById("myFile").value = "";
                this.loadResult();
                nextProps.productUploadresponse.success = null;
            }
        }
        return true;
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ inputValue: value, name: value }, () => this.callforDebounce(value))
        }
    }

    handleInputChange = (value) => {
        this.setState({ email: value });
    }

    resetExport = () => {
        this.setState({
            isExportLoader: false,
            email: "",
            emailError: "",
       });
    }

    exportProduct = () =>{
        let validationFields = [
            { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "email", message: "", errName: "emailError" },
        ];
        let { newArr, valid } = validate(validationFields);

        newArr.forEach((el) => this.setState({ [el.errName]: el.message }));

        if (valid) {
            let data = {
                filter: {
                    name: this.state.name !== "" ? this.state.name : undefined,
                    code: this.state.code !== "" ? this.state.code : undefined,
                    brand: this.state.filterBrand && this.state.filterBrand.value ? this.state.filterBrand.value : undefined
                },
                sort: sort,
                sortBy: sortBy,
                email: this.state.email,
            };
            this.props.productexport(data);
        }
    }

    callforDebounce = () => this.loadResult();

    render() {
        const { productmanagement_res } = this.props;
        const { name } = this.state;
        const totResult = productmanagement_res !== undefined ? productmanagement_res.count : 0;
        return (
            <main className="main-content">
                <ExportModal show={this.state.isExportLoader} title="Product" onClose={this.resetExport} onAction={this.exportProduct} onChange={this.handleInputChange} value={this.state.email} emailError={this.state.emailError}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="ml-3 mt-2">

                                    {
                                        this.state.isBackButton ? <div className="mr-3 mt-2 text-right">
                                            <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                                        </div> : null
                                    }
                                </div>
                                <div className="card-header">
                                <CardTitle title="Product Management"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    {isPermission("product", "create") ? 
                                                        <LinkWithItag to={routehelp.productmanagementadd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Product" />
                                                    : null}
                                                    <ButtonWithItag classNameI="fa tai-ico-Export" type="button" onclick={this.export} disabled={this.state.isExportLoader} className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Download all" />
                                                    <ButtonWithItag classNameI="ti-filter" onclick={this.toggleFilter} type="button" className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary mr-2" datatoggle="modal" datatarget=".bd-example-modal-lg1" dataplacement="top" title="Import a file" data-original-title="Click to Upload all" />
                                                    {/* <ButtonWithItag classNameI="fa tai-ico-Export" onclick={this.shadeUpload} type="button" className="btn btn-primary" datatoggle="modal" datatarget=".bd-example-modal-lg2" dataplacement="top" title="Export Unit Code By Shade" data-original-title="" /> */}
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"productSample.csv"}
                                                        className="btn btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade bd-example-modal-lg1" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">Product Upload</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className=" form-group required">
                                                            <Branddropdown
                                                                ondropdownChange={this.ondropdownChange}
                                                                name="brand"
                                                                value={this.state.brand}
                                                                className="form-control form-control-square"
                                                            />
                                                        </div>
                                                        <div className="custom-file mt-2 col">
                                                            <input accept=".csv" onChange={this.handleFile} type="file" id="myFile" name="filename2"></input>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" onClick={this.fileupload} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                        <button type="button" className="btn btn-secondary" onClick={this.clearFile} data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div tabIndex="-1" role="dialog" className="modal fade bd-example-modal-lg2" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">Shade Unit Code Upload</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="custom-file mt-2 col">
                                                            <input accept=".csv" onChange={this.handleUnitFile} type="file" id="myFileUnitCode" name="filename2"></input>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" onClick={this.clearFile} data-dismiss="modal">Close</button>
                                                        <button type="button" onClick={this.unitFileUpload} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"product"}/> :
                                            this.state.isLoading ? <Loader /> : productmanagement_res !== undefined && productmanagement_res.count === 0 ? "No Data Found" : productmanagement_res && productmanagement_res.count > 0 ? <div className="row">
                                                <div className="col-sm-12 display-grid">
                                                    <div className="table-responsive">
                                                        <DataTable mappingObj={this.state.isOnlineMapping} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={productmanagement_res} props={this.props} moduleName={"product"}/>
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}
                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button onClick={this.toggleFilter} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            {/* <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                                <div className="col-sm-9">
                                    <input maxlength="20" type="text" className="form-control" id="name" name="name" onChange={this.onChange} value={this.state.name} placeholder="Name" />
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label htmlFor="Mobile" className="col-sm-3 col-form-label">Code</label>
                                <div className="col-sm-9">
                                    <input maxlength="10" type="text" className="form-control" name="code" value={this.state.code} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                            </div>
                            <div className=" form-group">
                                <Branddropdown
                                    ondropdownChange={this.ondropdownChange}
                                    name="filterBrand"
                                    value={this.state.filterBrand}
                                    className="form-control form-control-square"
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark ">Reset</button>
                    </div>
                </div>
            </main>

        )
    }
}

function mapStateToProps(state) {
    return {
        productmanagement_res: state.productmanagement_res,
        product_delete: state.product_delete,
        productUploadresponse: state.productUploadresponse,
        productexportresponse: state.productexportresponse,
        unitCodeByShade_recieve: state.unitCodeByShade_recieve
    };
}


const mapDispatchToProps = dispatch => ({
    productmanagementlist: data => dispatch(productmanagementlist(data)),
    delete_product: item_id => dispatch(delete_product(item_id)),
    productUpload: file => dispatch(productUpload(file)),
    productexport: data => dispatch(productexport(data)),
    unitCodeByShade: unitFile => dispatch(unitCodeByShade(unitFile))
});

Productmanagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(Productmanagement);

export default Productmanagement;