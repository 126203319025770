import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { usermanagementlist, useractive, delete_user, userExport } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate, fileDownload, isPermission } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const Entries = lazy(() => import('../../components/entries/entires'));
const SearchInput = lazy(() => import('../../components/searchInput'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
class Usermanagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            mobile: '',
            usertype: '',
            isExportLoading: false,
            successRequest: [{ name: "user_delete", msg: 'User has been Deleted Successfully' }, { name: "useractive_update", msg: 'Successfully Done' }],
            serverErrors: ["usermanagement_res", "user_delete", "useractive_update", "userExport_res"],
            isLoading: false,
            isFilter: false,
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'code',
                    label: "Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'email',
                    label: "Email",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'phone',
                    label: "Mobile",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'type',
                    label: "User Type",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'isActive',
                    label: "Status",
                    type: "checkbox",
                    sortable: true,
                    isShow: true,
                    function: this.checkhandler
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect
                    },
                    {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ],
            statusType:'',
            status: [
                { label: "All", value: 'all' },
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" }
            ],
            isBackButton: false
        }
    }

    statusType = () => {
        if (this.state.statusType === "active") {
           return  true;
        };
        if (this.state.statusType === "inactive") {
           return  false;
        };
    }; 

    export = () => {
        let data = {};
        data.filter = { name: this.state.name ? this.state.name : undefined, email: this.state.email, phone: this.state.mobile, type: this.state.usertype };
        data.filter.isActive = this.statusType();
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        this.setState({
            isExportLoading: true
        });
        this.props.userExport(data);
    };

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
        var params = this.queryStringParse(this.props.location.search);
        if (params && params.dashboard === 'true') {
            // console.log(params);
            this.setState({
                isBackButton: true
            });

        }
    }

    queryStringParse = function (string) {
        let parsed = {};
        if (string) {
            string = string.substring(string.indexOf('?') + 1)
            let p1 = string.split('&')
            p1.forEach((value) => {
                let params = value.split('=')
                parsed[params[0]] = params[1]
            });
        }
        return parsed
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter })

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {};
        data.filter = { name: this.state.name ? this.state.name : undefined, email: this.state.email, phone: this.state.mobile, type: this.state.usertype};
        data.filter.isActive = this.statusType();
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip =  (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.usermanagementlist(data);
        this.setState({ isLoading: true, isFilter: false });
    }


    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    resetfilter = () => {
        let data = {}
        data.filter = { name: undefined, mobile: undefined, type: undefined, isActive: undefined }
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.usermanagementlist(data);
        this.setState({ isFilter: false, isLoading: true, name: '', usertype: "", mobile: '', statusType: ""  })
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }


    checkhandler = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
        let id = e.target.name;
        let data = {
            isActive: e.target.checked
        }
        this.props.useractive(data, id)
    }



    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_user(item._id);
            e.preventDefault();
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "usermanagement_res") });
            }

            if (nextProps.userExport_res !== undefined && nextProps.userExport_res.success === true) {
                fileDownload(nextProps, "userExport_res", "fileName");
                this.setState({
                    isExportLoading: false
                });
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el, el === "useractive_update" ? this.loadResult : undefined));
            this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg));
        }
        return true;
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }
    callforDebounce = () => this.loadResult();

    render() {
        // console.log(this.state.isBackButton);
        const { usermanagement_res } = this.props;
        const { name } = this.state;
        const totResult = usermanagement_res !== undefined ? usermanagement_res.count : 0;
        return (

            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">

                                {
                                    this.state.isBackButton ? <div className="mr-3 mt-2 text-right">
                                        <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                                    </div> : null
                                }

                                <div className="card-header">
                                <CardTitle title="User Management"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    <ButtonWithItag classNameI="fa tai-ico-Export" disabled={this.state.isExportLoading} onclick={this.export} type="button" className="btn float-left btn-primary mr-2" title="Export as a file" data-toggle="tooltip" data-placement="top" data-original-title="Click to Upload all" />
                                                    {isPermission("users", "create") ? 
                                                        <LinkWithItag to={routehelp.usermanagementadd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add User" />
                                                    : null}
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary navbar-toggler right-sidebar-toggler" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="table-responsive">
                                                    {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props}  moduleName={"users"}/> :
                                                        this.state.isLoading ? <Loader /> : usermanagement_res !== undefined && usermanagement_res.count === 0 ? "No Data Found" : usermanagement_res && usermanagement_res.count > 0 ?
                                                            <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={usermanagement_res} props={this.props}  moduleName={"users"}/>
                                                            : "Something Went Wrong"}
                                                </div>
                                            </div>
                                        </div>
                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" className="close" onClick={this.toggleFilter} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            {/* <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                                <div className="col-sm-9">
                                    <input maxlength="20" type="text" name="name" value={this.state.name} onChange={this.onChange} className="form-control" id="name" placeholder="Name" />
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label htmlFor="Mobile" className="col-sm-3 col-form-label">Mobile</label>
                                <div className="col-sm-9">
                                    <input maxlength="10" type="text" name="mobile" value={this.state.mobile} onChange={this.onChange} className="form-control" id="Mobile" placeholder="Mobile" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="Mobile" className="col-sm-3 col-form-label">User Type</label>
                                <div className="col-sm-9">
                                    <select className="form-control" name="usertype" value={this.state.usertype} onChange={this.onChange} id="usertype">
                                        <option value="">Select a Type</option>
                                        <option value="AG">AGENT</option>
                                        <option value="ASM">AREA SALES MANAGER</option>
                                        <option value="ASM2">AREA SALES MANAGER 2</option>
                                        <option value="SASM">Sr. AREA SALES MANAGER</option>
                                        <option value="WHS"> WHOLESALER</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Status</label>
                                <div className="col-sm-9">
                                    <select className="form-control" name="statusType" value={this.state.statusType} onChange={this.onChange} id="statusType">
                                    {
                                        this.state.status.map((ele, key) =>
                                            <option value={ele.value} id={key} key={key}>{ele.label}</option>
                                        )
                                    }
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark ">Reset</button>
                    </div>
                </div>
            </main>


        )
    }
}

function mapStateToProps(state) {
    return {
        usermanagement_res: state.usermanagement_res,
        user_delete: state.user_delete,
        useractive_update: state.useractive_update,
        userExport_res: state.userExport_res
    };
}


const mapDispatchToProps = dispatch => ({
    usermanagementlist: data => dispatch(usermanagementlist(data)),
    delete_user: item_id => dispatch(delete_user(item_id)),
    useractive: (data, id) => dispatch(useractive(data, id)),
    userExport: (data) => dispatch(userExport(data))
});

Usermanagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(Usermanagement);

export default Usermanagement;