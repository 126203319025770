import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routemanagementlist, delete_route, routeOrdermanagementlist, customermanagementlist, closeTour, srasmassignList } from '../../actions';
import { routehelp } from '../../Routehelper';
import LinkWithItag from '../../components/linkwithItag';
import Pagination from '../../components/pagination/index';
import Entries from '../../components/entries/entires';
import ButtonWithItag from '../../components/buttonwithTagi';
import LinksWithI from '../../components/linkwithItag';
import toastr from 'reactjs-toastr';
import Loader from '../../components/loader';
import Select from 'react-select';
import Userdropdown from '../userManagement/userDropdwon';
import CustomerDropDown from '../customerManagement/customerDropdown';
import SearchInput from '../../components/searchInput';
import Moment from 'moment';
import Map from '../../components/map';
import 'reactjs-toastr/lib/toast.css';
import { errorRender, commonDebounce, loaderUpdate, successRender, isPermission } from '../../helpers/functions';
const CardTitle = lazy(() => import('../../components/cardTitle'));
require('react-datetime');
var dateFormat = require('dateformat');

let itemsPerPage = 50;
let currentPage = 1;
let sortBy = '';
let sort = '';



class Routemanagement extends Component {
    constructor(props){
        super(props);
        this.state = {
            check: '',
            name: '',
            code: '',
            show: false,
            isLoading: false,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: "",
            line_array: [],
            customer: [],
            user: null,
            startdate: '',
            from: "",
            to: '',
            enddate: '',
            plannedstartdate: '',
            plannedenddate: '',
            agent: '',
            date: '',
            isFilter: false,
            srASMoptions: [],
            srAsmSelection: null,
            srASMusers: undefined,
            htmlDisplay: [],
            serverError: ["route_delete", "closeTourRes_data"]
        }
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.filter) {
            this.setState({
                name: this.props.location.state.filter.name || null,
                srAsm: this.props.location.state.filter.srAsm || null,
                user: this.props.location.state.filter.agent || null,
                customer: this.props.location.state.filter.customer || null,
                startDate: this.props.location.state.filter.startDate || null,
                endDate: this.props.location.state.filter.endDate || null,
                plannedAtFrom: this.props.location.state.filter.plannedAtFrom || null,
                plannedAtTo: this.props.location.state.filter.plannedAtTo || null
            }, () => this.loadResult(true));
        } else {
            this.loadResult();
        };

        this.props.srasmassignList({
            sort: 'asc',
            skip: 0,
            limit: 100000,
            sortBy: 'name'
        });
    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    loadResult = (options = false) => {
        if (this.state.isFilter === true || options === true) {
            currentPage = 1;
            if (this.state.user !== '' || this.state.customer !== null || this.state.startdate !== '' || this.state.enddate !== '' || this.state.plannedstartdate !== '' || this.state.plannedenddate !== '') {
                let data = {}
                data.filter = { srAsm: this.state.srAsmSelection && this.state.srAsmSelection.value ? this.state.srAsmSelection.value : undefined, agent: this.state.user && this.state.user !== '' && this.state.user.value ? this.state.user.value : undefined, customer: this.state.customer && this.state.customer.value ? this.state.customer.value : undefined, createdAtFrom: this.state.startdate !== '' ? this.state.startdate : undefined, createdAtTo: this.state.enddate !== '' ? this.state.enddate : undefined, plannedAtFrom: this.state.plannedstartdate !== '' ? this.state.plannedstartdate : undefined, plannedAtTo: this.state.plannedenddate !== '' ? this.state.plannedenddate : undefined }
                data.sortBy = sortBy === "" ? "createdAt" : sortBy;
                data.sort = sort === "" ? "desc" : sort;
                data.skip = (currentPage - 1) * itemsPerPage;
                data.limit = itemsPerPage;
                this.props.routemanagementlist(data);
                this.setState({ isLoading: true, isFilter: false })
            } else {
                toastr.error('Please Select atleast one field ', 'Error');
            }
        } else {
            let data = {}
            data.filter = { name: this.state.name ? this.state.name : undefined, srAsm: this.state.srAsmSelection && this.state.srAsmSelection.value ? this.state.srAsmSelection.value : undefined, agent: this.state.user && this.state.user !== '' && this.state.user.value ? this.state.user.value : undefined, customer: this.state.customer && this.state.customer.value ? this.state.customer : undefined, createdAtFrom: this.state.startdate !== '' ? this.state.startdate : undefined, createdAtTo: this.state.enddate !== '' ? this.state.enddate : undefined, plannedAtFrom: this.state.plannedstartdate !== '' ? this.state.plannedstartdate : undefined, plannedAtTo: this.state.plannedenddate !== '' ? this.state.plannedenddate : undefined }
            data.sortBy = sortBy === "" ? "createdAt" : sortBy;
            data.sort = sort === "" ? "desc" : sort;
            data.skip = (currentPage - 1) * itemsPerPage;
            data.limit = itemsPerPage;
            this.props.routemanagementlist(data);
            this.setState({ isLoading: true, isFilter: false });
        };

    }

    location = (agent, item) => {
        this.setState({
            agent: '',
            from: Moment(item.startDate).format('DD-MM-YYYY'),
            to: Moment(item.endDate).format('DD-MM-YYYY')
        }, () => {
            let data = {}
            data.filter = { agent: agent, createdAt: { from: item.startDate, to: item.endDate } }
            this.props.routeOrdermanagementlist(data);
        });
    }


    tourCloseFun = (id) => {
        let data = {
            isTourClosed: true
        };
        this.props.closeTour(data, id);
    };


    resetfilter = () => {
        let data = {}
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.setState({ srAsmSelection: null, isLoading: true, isFilter: false, user: null, customer: null, startdate: '', enddate: '', plannedstartdate: '', plannedenddate: '' })
        this.props.routemanagementlist(data);
    }

    openandclosrfilter = () => this.setState({ isFilter: !this.state.isFilter });

    user = () => {
        this.setState({
            agent: '',
            date: ''
        })
    }


    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item_id) => {
        this.props.delete_route(item_id);
        e.preventDefault();
    }

    ondropdownuser = (e, t) => this.setState({ [e]: t });

    componentWillReceiveProps(props) {
        if (props.routeOrder_res && props.routeOrder_res.list && props.routeOrder_res.count !== 0 && props.routeOrder_res.list[0].agent !== undefined) {
            this.setState({
                agent:
                    props.routeOrder_res !== undefined && props.routeOrder_res.list
                        ? props.routeOrder_res.list[0].agent.name
                        : '',
            });
        } else {
            this.setState({ agent: 'Not Found', from: 'Not Found', to: 'Not Found' });
        }
        return true;
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (nextProps.srasmassignList_response !== undefined) {
                if (nextProps.srasmassignList_response.success === true) {
                    let data = []
                    nextProps.srasmassignList_response.success = null;
                    nextProps.srasmassignList_response.list.forEach(item => {
                        data.push({ value: item.srAsm._id, label: item.srAsm.name, data: item })
                    })
                    this.setState({ srASMoptions: data });
                }
            }
            successRender(nextProps, "closeTourRes_data", () => this.loadResult('listApi'), "Tour Has been Closed Successfully")
            successRender(nextProps, "route_delete", this.loadResult, "Plan has been Deleted Successfully")
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "routemanagement_res") });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el))
        }
        return true;
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props.name,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    srAsmChange = (val) => {
        let data = []
        if (val) {
            val.data && val.data.agent && val.data.agent.forEach((agent) => {
                data.push({ label: `${agent.name} (${"Agent"})`, value: agent._id });
            })
            val.data && val.data.asm && val.data.asm.forEach((asm) => {
                data.push({ label: `${asm.name} (${"ASM"})`, value: asm._id });
            })
            val.data && val.data.asm2 && val.data.asm2.forEach((asm2) => {
                data.push({ label: `${asm2.name} (${"ASM2"})`, value: asm2._id });
            })
            this.setState({ srAsmSelection: val, srASMusers: data, agent: null, user: null, customer: null });
        } else {
            this.setState({ srAsmSelection: null, srASMusers: undefined, agent: null, user: null, customer: null })
        }
    }


    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ inputValue: value, name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    openAlert = (data) => {
        this.setState({
            htmlDisplay: data
        });
    }

    render() {
        const pathCoordinates = []
        const { routemanagement_res, routeOrder_res } = this.props;
        const { inputValue } = this.state;
        const totResult = routemanagement_res !== undefined ? routemanagement_res.count : 0;
        const name = this.state.selectedPlace
        if (routeOrder_res !== undefined && routeOrder_res.list) {
            routeOrder_res.list.forEach((item) => {
                if (item.latitude && item.latitude !== "" && item.longitude && item.longitude !== "") {
                    pathCoordinates.push({
                        lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)
                    })
                }
            })
        }
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="  col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Route Planning"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={inputValue} />
                                                    {isPermission("routePlanning", "create") ? 
                                                        <LinkWithItag to={routehelp.addroute} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Route Plan" />
                                                    : null}
                                                    <ButtonWithItag classNameI="ti-filter" onclick={this.openandclosrfilter} type="button" className="btn btn-primary navbar-toggler right-sidebar-toggler" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? "No Record Found" :
                                            this.state.isLoading ? <Loader /> :
                                                <div className="col-sm-12 display-grid">
                                                    <div className="table-responsive">
                                                        <table id="bs4-table" className="table table-bordered dataTable" cellSpacing="0">
                                                            <thead>
                                                                <tr role="row">
                                                                    <th onClick={() => this.sortBy('name')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>Tour Name </th>
                                                                    <th onClick={() => this.sortBy('name')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>Tour Id </th>
                                                                    <th onClick={() => this.sortBy('srAsm')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>Sr Asm</th>
                                                                    <th onClick={() => this.sortBy('agent')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>User </th>
                                                                    <th >Customer</th>
                                                                    <th onClick={() => this.sortBy('startDate')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>Start Date</th>
                                                                    <th onClick={() => this.sortBy('endDate')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>End Date</th>
                                                                    <th onClick={() => this.sortBy('createdAt')} className={sort === ''
                                                                        ? 'sorting'
                                                                        : sort === 'asc'
                                                                            ? 'sorting_asc'
                                                                            : 'sorting_desc'}>Created At</th>
                                                                    <th >Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {routemanagement_res !== undefined && routemanagement_res.list && routemanagement_res.list.length > 0 ? routemanagement_res.list.map((item, index) =>
                                                                    <tr key={index} role="row" className="odd notification-list">
                                                                        <td>{item.name ? item.name : "N/A"}</td>
                                                                        <td >{item.tourId ? item.tourId : 'N/A'}</td>
                                                                        <td>{item.srAsm && item.srAsm.name ? item.srAsm.name : "N/A"}</td>
                                                                        <td>{item.agent && item.agent.name}</td>
                                                                        <td>
                                                                            {
                                                                                item.customers && item.customers.length < 5 ?
                                                                                    item.customers.map((items, inx) =>
                                                                                        <div className={'show-list-more'} key={inx}>
                                                                                            <p key={inx}>{inx + 1}) {items.name}<br /></p>
                                                                                        </div>
                                                                                    ) :
                                                                                    <div>
                                                                                        {
                                                                                            item.customers.map((items, inx) =>
                                                                                                <div className='show-list-more' key={inx}>
                                                                                                    {inx < 5 ? <p key={inx}>{inx + 1}) {items.name}<br /></p> : null}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <a href='true' data-toggle="modal" data-target="#showCustomer" onClick={(e) => this.openAlert(item.customers)}>Show More</a>
                                                                                    </div>
                                                                            }
                                                                        </td>
                                                                        <td>{dateFormat(new Date(item.startDate), 'mmm d, yyyy')}</td>
                                                                        <td>{dateFormat(new Date(item.endDate), 'mmm d, yyyy')}</td>
                                                                        <td>{dateFormat(new Date(item.createdAt), 'mmm d, yyyy')}</td>
                                                                        <td className="d-flex text-center">
                                                                            <ButtonWithItag onclick={() => this.location(item.agent._id, item)} datatoggle="modal" datatarget=".bd-example-modal-lg" type="button" classNameI="ti-location-arrow" className="btn btn-link text-action-btn mr-1" data-toggle="tooltip" data-placement="top" title="" data-original-title="location" />
                                                                            {item.isTourClosed && item.isTourClosed === true && !isPermission("routePlanning", "update") ? null : <LinksWithI to={routehelp.updateroute + item._id} item_id={item._id} type="button" classNameI="ti-pencil" className="btn btn-link text-action-btn mr-1" />}
                                                                            {item.isTourClosed && item.isTourClosed === true && !isPermission("routePlanning", "delete") ? null : <ButtonWithItag onclick={ev => { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteRow(ev, item._id); }} type="button" classNameI="ti-trash" className="btn btn-link text-danger action-btn mr-1" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" />}
                                                                            {item.isTourClosed && item.isTourClosed === true ? null : <ButtonWithItag onclick={ev => { if (window.confirm('Are you sure you wish to close this tour ?')) this.tourCloseFun(item._id) }} type="button" classNameI="ti-close" className="btn btn-link text-danger action-btn mr-1" data-toggle="tooltip" data-placement="top" title="Tour close" data-original-title="Delete" />}
                                                                            {isPermission("routePlanning", "view") ? 
                                                                                <LinksWithI to={{
                                                                                    pathname: routehelp.touringVoucher,
                                                                                    state: {
                                                                                        tourData: item,
                                                                                        filter: {
                                                                                            name: this.state.name ? this.state.name : undefined,
                                                                                            srAsm: this.state.srAsmSelection,
                                                                                            agent: this.state.user,
                                                                                            customer: this.state.customer,
                                                                                            createdAtFrom: this.state.startdate !== '' ? this.state.startdate : undefined,
                                                                                            createdAtTo: this.state.enddate !== '' ? this.state.enddate : undefined,
                                                                                            plannedAtFrom: this.state.plannedstartdate !== '' ? this.state.plannedstartdate : undefined,
                                                                                            plannedAtTo: this.state.plannedenddate !== '' ? this.state.plannedenddate : undefined,
                                                                                            sortBy: sortBy === "" ? "createdAt" : sortBy,
                                                                                            sort: sort === "" ? "desc" : sort,
                                                                                            skip: (currentPage - 1) * itemsPerPage,
                                                                                            limit: itemsPerPage
                                                                                        },
                                                                                        backFrom: 'routePlanning'
                                                                                    }
                                                                                }}
                                                                                title="Touring Voucher" type="button" classNameI="ti-eye" className="btn btn-link text-primary action-btn mr-1" />
                                                                            : null}
                                                                        </td>
                                                                    </tr>
                                                                ) :
                                                                    <tr>
                                                                        <td colSpan={9}>
                                                                            <span style={{ textAlign: "center" }} className="text-center">No Record Found</span>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                        }
                                        {totResult > 0 ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <CardTitle title={'AGENT :'+ `${this.state.agent}` +', StartDate:' +`${this.state.from}` +'- End:'+ `${this.state.to}`} type="h5"/>
                                <h5 className="modal-title" id="myLargeModalLabel"> </h5>
                                <button onClick={this.user} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="model-body">
                                <Map name={name} pathCoordinates={pathCoordinates} showingInfoWindow={this.state.showingInfoWindow}
                                    activeMarker={this.state.activeMarker}
                                    selectedPlace={this.state.selectedPlace} onMarkerClick={this.onMarkerClick} ordermanagement_res={routeOrder_res} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title">Filter</h6>
                        <button onClick={this.openandclosrfilter} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                <label className="control-label">{"Sr. ASM"} </label>
                                <Select
                                    onChange={this.srAsmChange}
                                    value={this.state.srAsmSelection}
                                    options={this.state.srASMoptions}
                                    placeholder={"Select SrASM"}
                                    // isMulti={isMulti}
                                    isSearchable={true}
                                    isClearable={true}
                                />
                            </div>
                            <Userdropdown
                                ondropdownChange={this.ondropdownuser}
                                name="user"
                                value={this.state.user}
                                className="form-control"
                                type="User"
                                placeholder="User"
                                srASMusers={this.state.srASMusers}
                            />
                            <div className=" form-group">
                                <CustomerDropDown
                                    placeholder='Select Customer'
                                    ondropdownChange={(e) => this.ondropdownuser("customer", e)}
                                    value={this.state.customer}
                                />
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Created Start Date</label>
                                    <input maxlength="10" type="date" className="form-control" name="startdate" value={this.state.startdate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Created End Date</label>
                                    <input maxlength="10" type="date" className="form-control" name="enddate" value={this.state.enddate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Planned Start Date</label>
                                    <input maxlength="10" type="date" className="form-control" name="plannedstartdate" value={this.state.plannedstartdate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Planned End Date</label>
                                    <input maxlength="10" type="date" className="form-control" name="plannedenddate" value={this.state.plannedenddate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark ">Reset</button>
                    </div>
                </div>

                <div class="modal fade customer-data-modal" id="showCustomer" >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Customers List</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    this.state.htmlDisplay && this.state.htmlDisplay.length > 0 ? this.state.htmlDisplay.map((ele, index) =>
                                        <p>{index + 1})&nbsp;{ele.name}</p>
                                    ) : <p className='text center'>No Data Found</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        )
    }
}

function mapStateToProps(state) {
    return {
        routemanagement_res: state.routemanagement_res,
        route_delete: state.route_delete,
        routeOrder_res: state.routeOrder_res,
        customermanagement_res: state.customermanagement_res,
        closeTourRes_data: state.closeTourRes_data,
        srasmassignList_response: state.srasmassignList_response,
    };
}

const mapDispatchToProps = dispatch => ({
    routemanagementlist: data => dispatch(routemanagementlist(data)),
    delete_route: item_id => dispatch(delete_route(item_id)),
    customermanagementlist: data => dispatch(customermanagementlist(data)),
    routeOrdermanagementlist: data => dispatch(routeOrdermanagementlist(data)),
    closeTour: (data, id) => dispatch(closeTour(data, id)),
    srasmassignList: data => dispatch(srasmassignList(data)),
});

Routemanagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(Routemanagement);

export default Routemanagement
