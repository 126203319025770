import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { indirectcustomermanagementlist, delete_indirectcustomer, indirectcustomerUpload, indirectcustomerexport, wholesalerSearch, region_search, territory_search, beat_search } from '../../actions';
import { routehelp } from '../../Routehelper';
import toastr from 'reactjs-toastr';
import Translate from '../../libs/translation';
import { CSVLink } from "react-csv";
import { errorRender, commonDebounce, redirect, loaderUpdate, successRender, validate, isPermission } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import 'reactjs-toastr/lib/toast.css';
import ExportModal from '../modal/list';


const Pagination = lazy(() => import('../../components/pagination/index'));
const Entries = lazy(() => import('../../components/entries/entires'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));
const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const SearchInput = lazy(() => import('../../components/searchInput'));
const CustomerDropDown = lazy(() => import('../customerManagement/customerDropdown'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();

const csvData = [
    ["ASM", "WHOLESELLER", "INDIRECTCUSTOMER", "ACCOUNTCODE"],
    ["ANIL GHUME", "KETAN TESTER", "MOVEMENT", "1"],
    ["ANIL GHUME", "RAJU TESTER", "FORWARD", "2"],
    ["ANIL GHUME", "ABHI TESTER", "MAKE MOVE", "3"],
    ["AR TESTER", "KETAN TESTER", "RESHMA SLIK", "4"],
    ["AR TESTER", "RAJU TESTER", "CRAIG FASHION", "5"],
    ["AR TESTER", "ABHI TESTER", "MAN MADE", "6"],
    ["BISHIP TESTER", "KETAN TESTER", "FB FABRICATION", "7"],
    ["BISHIP TESTER", "RAJU TESTER", "TOP TIP", "8"],
    ["BISHIP TESTER", "ABHI TESTER", "VALUE FABRICATION", "9"]
];
class IndirectCustomermanagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            mobile: '',
            usertype: '',
            isLoading: false,
            agent: '',
            wholesaler: '',
            zone: '',
            region: '',
            territory: '',
            beat: '',
            isFilter: false,
            filter: { customerType: "wholesaler", limit: 5000 },
            ids: [],
            selectAll: false,
            isExportLoading: false,
            serverErrors: ["indirectcustomer_delete", "indirectcustomermanagement_res", "indirectcustomerexportresponse"],
            successRequest: [{ name: "indirectcustomer_delete", msg: 'Indirect Customer has been Deleted Successfully' }],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'accountCode',
                    label: "Account Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'agent.name',
                    label: "Agent",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'wholesaler.name',
                    label: "Wholesaler",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ]
        }
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        this.setState(this.state);
    }

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined, phone: this.state.mobile, type: this.state.usertype, agent: this.state.agent && this.state.agent.value, wholesaler: this.state.wholesaler && this.state.wholesaler.value }
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.indirectcustomermanagementlist(data);
        this.setState({ isLoading: true, isFilter: false })
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'asc') {
            sort = 'desc';
        } else {
            sort = 'asc';
        }
        this.loadResult();
    }

    ondropdownChangeagentwholesale = (e, t) => this.setState({ [e]: t, filter: { filter: { agent: t.value }, customerType: 'wholesaler', limit: 5000 } });

    export = () => {
        this.setState({
            isExportLoading: true
        });
    }

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter })

    ondropdownChange = (e, t) => this.setState({ [t]: e });

    handleFile = event => {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].name.includes(".csv")) {
                file.append('file', event.target.files[0]);
            } else {
                document.getElementById("myFile").value = "";
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    };

    fileupload = (event) => {
        if (document.getElementById("myFile").value === "") {
            toastr.error('Please Select Csv file ', 'error', {
                displayDuration: 5000, width: '400px'
            });
        } else {
            this.props.indirectcustomerUpload(file);
            file = new FormData();
        }
    };

    resetfilter = () => {
        let data = {}
        data.filter = { name: undefined, phone: undefined, type: undefined, agent: undefined, wholesaler: undefined }
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.indirectcustomermanagementlist(data);
        this.setState({ isFilter: false, isLoading: true, name: '', mobile: '', usertype: '', agent: "", wholesaler: "" })
    };

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    };

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    };

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    };

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    };

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_indirectcustomer(item._id);
            e.preventDefault();
        }
    };

    shouldComponentUpdate(nextProps) {
        let req = {};

        if (nextProps !== this.props) {
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el))
            this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg))
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "indirectcustomermanagement_res") });
            }

            if (nextProps.indirectcustomerUploadresponse !== undefined && nextProps.indirectcustomerUploadresponse.success === false && this.props !== nextProps && nextProps.indirectcustomerUploadresponse.errors) {
                nextProps.indirectcustomerUploadresponse.errors.map((item, index) =>
                    toastr.error(Translate.translate(req, item.code), 'Error', {
                        displayDuration: 5000
                    })
                );
                this.setState({ wholesaler: "" });
                document.getElementById("myFile").value = "";
                nextProps.indirectcustomerUploadresponse.success = null;
            };

            if (nextProps.indirectcustomerUploadresponse !== undefined && nextProps.indirectcustomerUploadresponse.success === true && this.props !== nextProps) {
                let str = `Total Records -${nextProps.indirectcustomerUploadresponse.totalCount} \n Success Records -  ${nextProps.indirectcustomerUploadresponse.successCount}\n  Failed Records - ${nextProps.indirectcustomerUploadresponse.rejectedCount}`
                toastr.success(str, "Upload Details", {
                    displayDuration: 10000
                });
                this.setState({ wholesaler: "" }, () => document.getElementById("myFile").value = "", this.loadResult());
                nextProps.indirectcustomerUploadresponse.success = null;
            };

            if (nextProps.indirectcustomerexportresponse !== undefined && nextProps.indirectcustomerexportresponse.success === true) {
                nextProps.indirectcustomerexportresponse.success = null;
                toastr.success("The indirect customer list will be sent to you within 1 hour via email.", "Success", {
                    displayDuration: 5000,
                });
                this.setState({
                    isExportLoading: false,
                    email: '',

                });
            }
        }
        return true;
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    };

    handleInputChange = (value) => {
        this.setState({ email: value });
    }

    resetExport = () => {
        this.setState({
            isExportLoading: false,
            email: "",
            emailError: "",
       });
    }

    exportProduct = () =>{
        let validationFields = [
            { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "email", message: "", errName: "emailError" },
        ];
        let { newArr, valid } = validate(validationFields);

        newArr.forEach((el) => this.setState({ [el.errName]: el.message }));

        if (valid) {
             let data = {
            filter: {
                type: this.state.usertype,
                agent: this.state.agent && this.state.agent.value,
                wholesaler: this.state.wholesaler && this.state.wholesaler.value
            },
            sort: sort,
            sortBy: sortBy,
            email: this.state.email,
        };
        this.props.indirectcustomerexport(data);
        }
    }

    callforDebounce = () => this.loadResult();

    render() {
        const { name } = this.state;
        const { indirectcustomermanagement_res } = this.props;
        const totResult = indirectcustomermanagement_res !== undefined ? indirectcustomermanagement_res.count : 0;
        return (
            <main className="main-content">
                <ExportModal show={this.state.isExportLoading} title="Indirect Customer" onClose={this.resetExport} onAction={this.exportProduct} onChange={this.handleInputChange} value={this.state.email} emailError={this.state.emailError}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Indirect Customer Management"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    {isPermission("indirectCustomers", "create") ? 
                                                        <LinkWithItag to={routehelp.indirectCustomeradd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Indirect Customer" />
                                                    : null}
                                                    <ButtonWithItag classNameI="fa tai-ico-Export" disabled={this.state.isExportLoading} onclick={this.export} type="button" className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Upload all" />
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary mr-2" datatoggle="modal" datatarget=".bd-example-modal-lg" dataplacement="top" title="Import a file" data-original-title="Click to download all" />
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"indirectCustomerSample.csv"}
                                                        className="btn btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">Indirect Customer Upload</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="custom-file p-2 form-group required">
                                                            <input accept=".csv" onChange={this.handleFile} type="file" id="myFile" name="filename2"></input>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" onClick={this.fileupload} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"indirectCustomers"} /> :
                                            this.state.isLoading ? <Loader /> : indirectcustomermanagement_res !== undefined && indirectcustomermanagement_res.count === 0 ? "No Data Found" : indirectcustomermanagement_res && indirectcustomermanagement_res.count > 0 ? <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="table-responsive">
                                                        <DataTable mappingObj={this.state.isOnlineMapping} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={indirectcustomermanagement_res} props={this.props} moduleName={"indirectCustomers"} />
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}
                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <Userdropdown
                                type="ASM"
                                ondropdownChange={this.ondropdownChangeagentwholesale}
                                name="agent"
                                placeholder="Area Sales Manager"
                                value={this.state.agent}
                                className="form-control"
                            />
                            <CustomerDropDown
                                lable="Wholesaler"
                                value={this.state.wholesaler}
                                ondropdownChange={(e) => this.ondropdownChange(e, "wholesaler")}
                                placeholder="Search Wholesaler"
                                filterBy={this.state.filter}
                            />
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        indirectcustomermanagement_res: state.indirectcustomermanagement_res,
        indirectcustomer_delete: state.indirectcustomer_delete,
        region_response_search: state.region_response_search,
        territory_response_search: state.territory_response_search,
        beat_response_search: state.beat_response_search,
        indirectcustomerUploadresponse: state.indirectcustomerUploadresponse,
        indirectcustomerexportresponse: state.indirectcustomerexportresponse
    };
}

const mapDispatchToProps = dispatch => ({
    indirectcustomermanagementlist: data => dispatch(indirectcustomermanagementlist(data)),
    delete_indirectcustomer: item_id => dispatch(delete_indirectcustomer(item_id)),
    region_search: data => dispatch(region_search(data)),
    territory_search: data => dispatch(territory_search(data)),
    beat_search: data => dispatch(beat_search(data)),
    indirectcustomerUpload: file => dispatch(indirectcustomerUpload(file)),
    indirectcustomerexport: data => dispatch(indirectcustomerexport(data)),
    wholesalerSearch: data => dispatch(wholesalerSearch(data))
});

IndirectCustomermanagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(IndirectCustomermanagement);

export default IndirectCustomermanagement;