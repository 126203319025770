import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { trendlist } from '../../actions';
import { delete_trend } from '../../actions';
import { routehelp } from '../../Routehelper';
import Pagination from '../../components/pagination/index';
import Entries from '../../components/entries/entires';
import LinkWithItag from '../../components/linkwithItag';
import ButtonWithItag from '../../components/buttonwithTagi';
import DataTable from '../../components/dataTalble';
import Loader from '../../components/loader';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate, isPermission } from '../../helpers/functions';
import SearchInput from '../../components/searchInput';
const CardTitle = lazy(() => import('../../components/cardTitle'));

let itemsPerPage = 50;
let currentPage = 1;
let sortBy = '';
let sort = '';
class Trending extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: '',
            name: '',
            code: '',
            isLoading: false,
            isFilter: false,
            deleteMessage: 'Trand has been Deleted Successfully',
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'description',
                    label: "Description",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ]
        }
    }



    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined, code: this.state.code }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.trendlist(data);
        this.setState({ isLoading: true, isFilter: false })
    }



    resetfilter = () => {
        let data = {}
        data.filter = { name: '' }
        this.props.trendlist(data);
        this.setState({ isFilter: false, isLoading: true, name: '' })
    }


    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter })

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }


    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };


    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_trend(item._id);
            e.preventDefault();
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            successRender(nextProps, "trend_delete", this.loadResult, this.state.deleteMessage)
            errorRender(nextProps, "trend_delete");
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "trendlist_res") });
            }
        }
        return true;
    }


    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            currentPage = 1;
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }
    callforDebounce = () => this.loadResult();

    render() {
        const { trendlist_res } = this.props;
        const { name } = this.state;
        const totResult = trendlist_res !== undefined ? trendlist_res.count : 0;
        return (

            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Trendings"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    {isPermission("trending", "create") ? 
                                                        <LinkWithItag to={routehelp.trendAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Trending" />
                                                    : null}
                                                    <ButtonWithItag onclick={this.toggleFilter} classNameI="ti-filter" type="button" className="btn btn-primary navbar-toggler right-sidebar-toggler" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"trending"} /> :
                                            this.state.isLoading ? <Loader /> : trendlist_res !== undefined && trendlist_res.count === 0 ? "No Data Found" : trendlist_res && trendlist_res.count > 0 ? <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="table-responsive">
                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={trendlist_res} props={this.props} moduleName={"trending"} />
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}
                                        {totResult !== undefined ? trendlist_res !== undefined && trendlist_res.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                                <div className="col-sm-9">
                                    <input maxlength="20" type="text" name="name" value={this.state.name} onChange={this.onChange} className="form-control" id="name" placeholder="Name" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        trendlist_res: state.trendlist_res,
        trend_delete: state.trend_delete
    };
}


const mapDispatchToProps = dispatch => ({
    trendlist: data => dispatch(trendlist(data)),
    delete_trend: item_id => dispatch(delete_trend(item_id))
});

Trending = connect(
    mapStateToProps,
    mapDispatchToProps
)(Trending);

export default Trending;